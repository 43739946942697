import Observer from './vendor/observer.min';
import { formsPackage } from './functions/form';
import masksPackage from './functions/masks';
import setConditions from './functions/conditions';
import limitedCheckboxLists from './functions/limitedCheckboxLists';

export const OBSERVER = Observer.getInstance();

addEventListener('DOMContentLoaded', () => {
  initFormBuilder();
})


export const initFormBuilder = () => {
  const classeContainerFormBuilder = '.dynamicForm';
  formsPackage(classeContainerFormBuilder);
  masksPackage();
  initFileUpload(classeContainerFormBuilder);
  setConditions();
  limitedCheckboxLists();
  recaptchaFormsCallback(); // Décommenter si on utilise le recaptcha
};

window.initFormBuilder = initFormBuilder;
